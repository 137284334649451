import TableSearch from "@rio-cloud/rio-uikit/TableSearch"
import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar"
import { FormattedMessage, useIntl } from "react-intl"
import * as React from "react"
import { VolumeForecastDownloadButton } from "../../VolumeForecast/VolumeForecastDownloadButton"
import { MainRunButtonDropdown } from "../../MainRun/MainRunButtonDropdown"
import DatePicker from "@rio-cloud/rio-uikit/DatePicker"
import moment from "moment"
import { CSVExportButtonDropdown } from "../CSVExport/CSVExportButtonDropdown"
import { TransportOrder } from "../../../model/transportorder/transportOrder"
import { Moment } from "moment/moment"
import { extractTableParamsFromUrl } from "../../../redux/transportorder.slice"
import { FreightPaymentDetailsButton } from "../../FreightPaymentDetails/FreightPaymentDetailsButton"
import { v4 as uuid } from "uuid"

interface TableToolBarProps {
    onSearchPhraseChange: (value: string) => void
    location: string
    orderDateFrom: Date | undefined
    onChangeOrderDateFrom: (date: Date) => void
    orderDateTo: Date | undefined
    onChangeOrderDateTo: (date: Date) => void
    transportOrders: TransportOrder[]
}

export const TRANSPORT_ORDER_TABLE_SEARCH_TEST_ID = "TRANSPORT_ORDER_TABLE_SEARCH_TEST_ID"
export const TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_ID = uuid()
const TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_TEST_ID = "TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_TEST_ID"
const TRANSPORT_ORDER_TABLE_DATE_PICKER_TO_TEST_ID = "TRANSPORT_ORDER_TABLE_DATE_PICKER_TO_TEST_ID"

export function TableToolBar(props: TableToolBarProps): React.ReactElement {
    const {
        location,
        onChangeOrderDateFrom,
        onChangeOrderDateTo,
        onSearchPhraseChange,
        orderDateFrom,
        orderDateTo,
        transportOrders
    } = props


    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className={"table-toolbar-column"}>
                        <ToolBarDatePicker
                            testId={TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_TEST_ID}
                            labelId="transportOrder.overview.toolbar.orderDate.from"
                            onChange={onChangeOrderDateFrom}
                            selectedDate={orderDateFrom}
                            isValidDate={(from: moment.Moment) => orderDateTo === undefined || from.isBefore(orderDateTo)}
                        />
                    </div>
                    <div className={"table-toolbar-column table-toolbar-column-spacer"}>
                        <ToolBarDatePicker
                            testId={TRANSPORT_ORDER_TABLE_DATE_PICKER_TO_TEST_ID}
                            labelId="transportOrder.overview.toolbar.orderDate.to"
                            onChange={onChangeOrderDateTo}
                            selectedDate={orderDateTo}
                            isValidDate={(to: moment.Moment) => orderDateFrom === undefined || to.isSameOrAfter(orderDateFrom)}
                        />
                    </div>
                    <div className={"table-toolbar-column"}>
                        <FreightPaymentDetailsButton/>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className={"table-toolbar-column"}>
                        <MainRunButtonDropdown/>
                    </div>
                    <div className={"table-toolbar-column"}>
                        <VolumeForecastDownloadButton/>
                    </div>
                    <div className={"table-toolbar-column"}>
                        <CSVExportButtonDropdown fromDate={orderDateFrom} toDate={orderDateTo} transportOrders={transportOrders}/>
                    </div>
                    <div className="table-toolbar-column">
                        <TableSearch
                            data-testid={TRANSPORT_ORDER_TABLE_SEARCH_TEST_ID}
                            value={extractTableParamsFromUrl(location).searchPhrase}
                            onChange={onSearchPhraseChange}
                        />
                    </div>
                </div>
            </div>
        </TableToolbar>
    )
}

interface ToolBarDatePickerProps {
    labelId: string
    selectedDate: Date | undefined
    onChange: (date: Date) => void
    isValidDate: (date: moment.Moment) => boolean
    testId: typeof TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_TEST_ID | typeof TRANSPORT_ORDER_TABLE_DATE_PICKER_TO_TEST_ID
}

const ToolBarDatePicker = (props: ToolBarDatePickerProps) => {
    const { labelId, onChange, selectedDate, isValidDate, testId } = props
    const intl = useIntl()
    const datePickerInputProps: React.HTMLProps<HTMLInputElement> = {
        id: TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_ID,
    }

    if (selectedDate === undefined) {
        datePickerInputProps.value = "" // See https://github.com/arqex/react-datetime/issues/749
    }

    return <div data-testid={testId}>
        <label htmlFor={TRANSPORT_ORDER_TABLE_DATE_PICKER_FROM_ID}>
            <FormattedMessage id={labelId} />
        </label>
        <DatePicker
            locale={intl.locale}
            inputProps={datePickerInputProps}
            onChange={(date: Moment | string) => {
                if (moment.isMoment(date) && date.isValid()) {
                    onChange(date.toDate())
                }
            }}
            value={selectedDate}
            isValidDate={isValidDate}
            clearableInput={false}
            minWidth={140}
            timeFormat={false}
            mandatory={true}
        />
    </div>
}
