import { useGetFreightForwarderInfoQuery } from "../../client/freight-forwarder-info/freightForwarderInfoApi"
import { useFormState } from "react-hook-form"
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel"
import { FormattedMessage } from "react-intl"
import { FormTextInput } from "../Form/FormTextInput"
import { FormSelect } from "../Form/FormSelect"
import { meansOfTransport, TransportationReport } from "../../model/transportation-report/transportation-report"
import countries from "i18n-iso-countries"
import { FormDatePicker } from "../Form/FormDatePicker"

type TransportDetailsFormInput = Pick<TransportationReport, "transportDetails">

export const TransportDetailsPanel = () => {
    const { data, error, isLoading } = useGetFreightForwarderInfoQuery()
    const formState = useFormState<TransportDetailsFormInput>({
        name: "transportDetails",
    })

    const hasErrors = formState.errors.transportDetails !== undefined

    return <ExpanderPanel
        open
        title={<>
            {hasErrors ? <span className={"rioglyph rioglyph-error-sign padding-right-5"}/> : undefined}
            <FormattedMessage id={"freightPaymentDetails.transportData"}/>
        </>
        }
        iconLeft={true}
        bsStyle={"separator"}
        titleClassName={hasErrors ? "text-danger" : ""}
        unmountOnExit={false}
    >
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.recipientIdentifier"}
                    label={<FormattedMessage id={"invoice.recipient"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.invoice.recipient.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormSelect
                    fieldName={"transportDetails.freightForwarder.duns"}
                    label={<FormattedMessage id={"carrier.duns"}/>}
                    options={(data?.dunsNumbers ?? []).map(duns => ({ id: duns, label: duns, }))}
                    disabled={isLoading || error !== undefined}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.carrier.duns.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.freightForwarder.supplierCode"}
                    label={<FormattedMessage id={"carrier.supplierCode"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.carrier.supplierCode.required"}/> }}
                />
            </div>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormSelect
                    fieldName={"transportDetails.transportMode.meansOfTransport"}
                    label={<FormattedMessage id={"meansOfTransport.type"}/>}
                    options={meansOfTransport.map(it => ({
                        id: it,
                        label: <FormattedMessage id={`meansOfTransport.type.${it}`}/>
                    }))}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.meansOfTransport.type.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormSelect
                    fieldName={"transportDetails.transportMode.licensePlateCountryCode"}
                    label={<FormattedMessage id={"licensePlate.countryCode"}/>}
                    options={Object.keys(countries.getAlpha2Codes()).map((countryCode) => ({
                        id: countryCode,
                        label: countryCode,
                    }))}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.licensePlate.countryCode.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.transportMode.licensePlate"}
                    label={<FormattedMessage id={"licensePlate"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.licensePlate.required"}/> }}
                />
            </div>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<TransportDetailsFormInput>
                    fieldName={"transportDetails.borderoNumber"}
                    label={<FormattedMessage id={"transportOrder.confirmation.input.label.bordero"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.borderoNumber.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormDatePicker<TransportDetailsFormInput>
                    fieldName={"transportDetails.borderoDate"}
                    label={<FormattedMessage id={"transportDetails.bordero.date"}/>}
                    required={true}
                    allowTime={false}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.borderoDate.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}/>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormDatePicker<TransportDetailsFormInput>
                    fieldName={"transportDetails.timeWindow.from"}
                    label={<FormattedMessage id={"transportOrder.confirmation.input.label.loadingTimewindow.from"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.timeWindowFrom.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormDatePicker<TransportDetailsFormInput>
                    fieldName={"transportDetails.timeWindow.to"}
                    label={<FormattedMessage id={"transportOrder.confirmation.input.label.unloadingTimeWindow.to"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.timeWindowTo.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}/>
        </div>
    </ExpanderPanel>
}