import { FieldValues } from "react-hook-form/dist/types/fields"
import { FieldError, FieldErrorsImpl, FieldPathByValue, Merge, useController } from "react-hook-form"
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput"
import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

interface FormTextInputProps<T extends FieldValues> {
    fieldName: FieldPathByValue<T, string>
    label: ReactNode
    placeHolder?: ReactNode
    required?: boolean
    mask?: string | (string | RegExp)[];
    minLength?: number
    maxLength?: number
    validationMessageOverrides?: Partial<ErrorMessageMap>
}

export const FormTextInput = <T extends FieldValues>(props: FormTextInputProps<T>) => {
    const { field, fieldState } = useController<T>({
        name: props.fieldName,
        rules: {
            required: props.required,
            minLength: props.minLength,
            maxLength: props.maxLength,
        }
    })
    const showError = fieldState.error !== undefined

    return (
        <div className={`form-group ${showError ? "has-error has-feedback" : ""}`}>
            <label htmlFor={field.name}>{props.label}</label>
            <ClearableInput
                id={field.name}
                placeholder={props.placeHolder}
                mask={props.mask}
                {...field}
                maskPlaceholder={null}
            />
            <ErrorHelpBlock error={fieldState.error} minLength={props.minLength} maxLength={props.maxLength} validationMessageOverrides={props.validationMessageOverrides}/>
        </div>
    )
}

interface ErrorHelpBlockProps {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<object>>
    minLength?: number
    maxLength?: number
    validationMessageOverrides?: Partial<ErrorMessageMap>
}

interface ErrorMessageMap {
    required: ReactNode,
    minLength: ReactNode,
    maxLength: ReactNode,
}

const ErrorHelpBlock = (props: ErrorHelpBlockProps) => {
    const { error } = props

    if (!error) {
        return <></>
    }

    const errorMessages = {
        required: <FormattedMessage id={"validation.not.be.blank"} />,
        minLength: <FormattedMessage id={"validation.length.min"} values={{ num: props.minLength }} />,
        maxLength: <FormattedMessage id={"validation.length.max"} values={{ num: props.maxLength }} />,
        ...props.validationMessageOverrides
    }

    return (
        <>
            <span className={"help-block"}>
                {(error.type ? errorMessages[error.type] : "Unknown validation error") ?? "Unknown validation error"}
            </span>
            <span className={"form-control-feedback rioglyph rioglyph-error-sign"} />
        </>
    )
}
