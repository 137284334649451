import { z } from "zod"

export const transportationReportResponseRestSchema = z.object({
    report_id: z.string(),
})

export type TransportationReportResponseRest = z.infer<typeof transportationReportResponseRestSchema>

export const transportationReportRequestRestSchema = z.object({
    transport_details: z.object({
        recipient_identifier: z.string(),
        freight_forwarder: z.object({
            duns: z.string(),
            supplier_code: z.string(),
        }),
        transport_mode: z.object({
            type: z.enum(["ROAD"]),
            means_of_transport: z.enum([
                "EUROTRAILER",
                "MEGATRAILER",
                "JUMBO_SWAP_BODY",
                "JUMBO_TRAILER_PULL",
                "TRUCK_7_5_TON",
                "VAN_OR_BUS",
                "CAR",
                "GIGALINER",
                "TAUTLINER",
                "GOOSENECK_CHASSIS"
            ]),
            license_plate: z.string(),
            license_plate_country_code: z.string(),
        }),
        bordero_number: z.string(),
        bordero_date: z.string(),
        time_window: z.object({
            from: z.string(),
            to: z.string(),
        }),
    }),
    shipments: z.array(z.object({
        shipment_number: z.string(),
        loading: z.object({
            ship_from: z.object({
                duns: z.string(),
                supplier_code: z.string(),
            }),
        }),
        unloading: z.object({
            ship_to: z.object({
                duns: z.string(),
                plant_code: z.string(),
                supplier_code: z.string(),
            }),
            unloading_access_code: z.string(),
        }),
        delivery_notes: z.array(z.object({
            delivery_note_number: z.string(),
        })),
    })),
})

export type TransportationReportRequestRest = z.infer<typeof transportationReportRequestRestSchema>
