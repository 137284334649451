import { FieldValues } from "react-hook-form/dist/types/fields"
import { FieldError, FieldErrorsImpl, FieldPathByValue, Merge, useController } from "react-hook-form"
import { ReactNode } from "react"
import { useAppSelector } from "../../redux/store"
import { getProfileLocale } from "../../redux/lang.selector"
import DatePicker from "@rio-cloud/rio-uikit/DatePicker"
import moment from "moment/moment"
import { FormattedMessage } from "react-intl"

interface FormDatePickerProps<T extends FieldValues> {
    fieldName: FieldPathByValue<T, string>
    label: ReactNode
    required?: boolean
    allowTime?: boolean
    validationMessageOverrides?: Partial<ErrorMessageMap>
}

export const FormDatePicker = <T extends FieldValues>(props: FormDatePickerProps<T>) => {
    const locale: string | undefined = useAppSelector(getProfileLocale)
    const { field, fieldState } = useController<T>({ name: props.fieldName, rules: { required: props.required } })

    const hasError = fieldState.error !== undefined

    return (
        <div className={`form-group ${hasError ? "has-feedback has-error" : ""}`}>
            <label htmlFor={field.name}>{props.label}</label>
            <div className={"input-group"}>
                <DatePicker
                    className={"width-100pct margin-0"}
                    id={field.name}
                    value={field.value ? moment(field.value) : undefined}
                    locale={locale}
                    timeFormat={props.allowTime}
                    onChange={(value, isValid) => {
                        field.onChange(isValid ? moment(value).toISOString(true) : undefined)
                    }}
                />
            </div>
            <ErrorHelpBlock error={fieldState.error} validationMessageOverrides={props.validationMessageOverrides}/>
        </div>
    )
}

interface ErrorHelpBlockProps {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<object>>
    validationMessageOverrides?: Partial<ErrorMessageMap>
}

interface ErrorMessageMap {
    required: ReactNode,
}

const ErrorHelpBlock = (props: ErrorHelpBlockProps) => {
    const { error } = props

    if (!error) {
        return <></>
    }

    const errorMessages = {
        required: <FormattedMessage id={"validation.not.be.blank"} />,
        ...props.validationMessageOverrides
    }

    return (
        <>
            <span className={"help-block"}>
                {(error.type ? errorMessages[error.type] : "Unknown validation error") ?? "Unknown validation error"}
            </span>
            <span className={"form-control-feedback rioglyph rioglyph-error-sign"} />
        </>
    )
}
