import { ReactElement, useState } from "react"
import { ConfirmationInformationPopup } from "../../Application/ConfirmationInformationPopup"
import { TransportOrder } from "../../../model/transportorder/transportOrder"
import { Confirmation } from "../../../model/confirmation/Confirmation"
import Button from "@rio-cloud/rio-uikit/Button"
import { FormattedMessage } from "react-intl"

interface Props{
  onClick: () => Promise<void>
  disabled?: boolean
  transportOrder?: TransportOrder
  confirmations?: Confirmation[]
}

export const ConfirmationActionButton = (props: Props): ReactElement => {
    const [state, setState] = useState({ inProgress: false, success: false })
    const { onClick, disabled, transportOrder, confirmations } = props
    const handleClick = (): Promise<void> => {
        setState({ ...state, inProgress: true })

        return onClick()
            .then(() => {
                setState({ success: true, inProgress: false })
                return Promise.resolve()
            })
            .catch(() => {
                setState({ success: false, inProgress: false })
                return Promise.resolve()
            })
    }

    return (
        <ConfirmationInformationPopup
            onPopupNotShown={handleClick}
            renderChildren={(triggerPopup): ReactElement =>
                <Button
                    bsStyle={Button.PRIMARY}
                    disabled={disabled || state.inProgress}
                    onClick={() => !state.inProgress && !state.success && triggerPopup()}
                    className={state.inProgress ? "btn-loading-overlay": ""}
                >
                    <FormattedMessage id={"transportOrder.accept"} />
                </Button>
            }
            transportOrder={transportOrder}
            confirmations={confirmations}
        />
    )
}
