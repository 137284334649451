import {
    Address,
    Article,
    HandlingUnit,
    Packable,
    Shipment,
    ShipmentPriority,
    TransportOrder,
} from "../model/transportorder/transportOrder"
import { LengthMeasurementUnit } from "../model/transportorder/measurement"
import { v4 as uuid } from "uuid"

export const createEmptyAddress = (): Address => ({ street: "", city: "", postal_code: "", country: "DE" })

export const createHandlingUnit = (content: Packable[] = []): HandlingUnit => ({
    type: "HANDLING_UNIT",
    _frontendKey: `${uuid()}`,
    content: content,
    stacking_factor: 1,
    quantity: 1,
    packaging_material_id: "EURO",
    dimension: {
        length: { value: 800, unit: LengthMeasurementUnit.MMT },
        width: { value: 1200, unit: LengthMeasurementUnit.MMT }
    }
})

export const createEmptyArticle = (): Article => ({ type: "ARTICLE", _frontendKey: `${uuid()}`, quantity: 1, name: "VW Currywurst" })

export const containsUrgentShipment = (shipments?: Shipment[]): boolean => {
    const shipmentPriority = shipments?.find(shipment => shipment.priority === ShipmentPriority.URGENT)?.priority
    return shipmentPriority === ShipmentPriority.URGENT
}

export const getShipmentFromTransportOrder = (transportOrder: TransportOrder, shipmentId: string): Shipment | undefined => {
    const shipments = (transportOrder._embedded?.shipments ?? [])
    return shipmentId ? shipments.find(it => it.id === shipmentId) : shipments[0]
}
