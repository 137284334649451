import { FieldValues } from "react-hook-form/dist/types/fields"
import { FieldPathByValue, useController } from "react-hook-form"
import Select from "@rio-cloud/rio-uikit/Select"
import { SelectOption } from "@rio-cloud/rio-uikit"
import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

interface FormSelectProps<T extends FieldValues> {
    fieldName: FieldPathByValue<T, string>
    label: ReactNode
    options: { id: string; label: ReactNode }[]
    disabled?: boolean
    required?: boolean
    validationMessageOverrides?: Partial<ErrorMessageMap>
}

interface ErrorMessageMap {
    required: ReactNode,
}

export const FormSelect = <T extends FieldValues>(props: FormSelectProps<T>) => {
    const { field, fieldState } = useController<T>({
        name: props.fieldName,
        rules: { required: props.required }
    })

    const errorMessages = {
        required: <FormattedMessage id={"validation.not.be.blank"} />,
        ...props.validationMessageOverrides
    }

    const hasError = fieldState.error !== undefined
    const errorType = fieldState.error?.type
    let errorMessage: ReactNode = undefined
    if (hasError) {
        if (errorType !== undefined) {
            errorMessage = errorMessages[errorType] ?? "Unknown validation error"
        } else {
            errorMessage = "Unknown validation error"
        }
    }

    return (
        <div className={"form-group"}>
            <label htmlFor={field.name}>{props.label}</label>
            <div className={"input-group"}>
                <Select
                    className={"width-100pct"}
                    id={field.name}
                    options={props.options.map(option => ({ ...option, selected: option.id === field.value }))}
                    onChange={(selectedOption?: SelectOption) => field.onChange(selectedOption?.id)}
                    useFilter={true}
                    disabled={props.disabled}
                    errorMessage={errorMessage}
                />
            </div>
        </div>
    )
}