import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog"
import { FormattedMessage } from "react-intl"

export interface SubmitConfirmationDialogProps {
    show: boolean;
    disableConfirm: boolean;
    onClickConfirm: () => void;
    onClickCancel: () => void;
}

export const SUBMIT_CONFIRMATION_DIALOG_TEST_ID = "SUBMIT_CONFIRMATION_DIALOG_TEST_ID"

export const SubmitConfirmationDialog = (props: SubmitConfirmationDialogProps) => {
    const { show, disableConfirm, onClickConfirm, onClickCancel } = props
    return (
        <ConfirmationDialog
            data-testid={SUBMIT_CONFIRMATION_DIALOG_TEST_ID}
            show={show}
            title={<FormattedMessage id={"freightPaymentDetails.submitConfirmationDialog.title"}/>}
            content={
                <p className={"white-space-pre-line"}>
                    <FormattedMessage id={"freightPaymentDetails.submitConfirmationDialog.description"}/>
                </p>
            }
            onClickConfirm={onClickConfirm}
            onClickCancel={onClickCancel}
            cancelButtonText={<FormattedMessage id={"freightPaymentDetails.submitConfirmationDialog.cancel"}/>}
            confirmButtonText={
                <>
                    <span className='rioglyph rioglyph-ok margin-right-5'/>
                    <span><FormattedMessage id={"freightPaymentDetails.submitConfirmationDialog.confirm"}/></span>
                </>
            }
            disableConfirm={disableConfirm}
            useOverflow={false}
            bsSize={"sm"}
        />
    )
}