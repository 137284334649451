import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getTokenFromSessionStorage } from "../common/auth"
import { config } from "../../configuration/app/app-config"
import {
    TransportationReport,
    TransportationReportResult
} from "../../model/transportation-report/transportation-report"
import {
    TransportationReportRequestRest,
    TransportationReportResponseRest,
    transportationReportResponseRestSchema
} from "./TransportationReport.model"
import moment from "moment"

const TRANSPORTATION_REPORT_API_TAG = "TRANSPORTATION_REPORT"

const transportationReportBaseUrl = config.shipmentTrackingApi.baseUrl

const toTransportationReportRest = (src: TransportationReport): TransportationReportRequestRest => ({
    transport_details: {
        recipient_identifier: src.transportDetails.recipientIdentifier,
        freight_forwarder: {
            duns: src.transportDetails.freightForwarder.duns,
            supplier_code: src.transportDetails.freightForwarder.supplierCode,
        },
        time_window: {
            from: src.transportDetails.timeWindow.from,
            to: src.transportDetails.timeWindow.to,
        },
        bordero_number: src.transportDetails.borderoNumber,
        bordero_date: moment(src.transportDetails.borderoDate).format("YYYY-MM-DD"),
        transport_mode: {
            type: src.transportDetails.transportMode.type,
            means_of_transport: src.transportDetails.transportMode.meansOfTransport,
            license_plate: src.transportDetails.transportMode.licensePlate,
            license_plate_country_code: src.transportDetails.transportMode.licensePlateCountryCode,
        },
    },
    shipments: src.shipments.map((shipment) => ({
        shipment_number: shipment.shipmentNumber,
        loading: {
            ship_from: {
                duns: shipment.loading.shipFrom.duns,
                supplier_code: shipment.loading.shipFrom.supplierCode,
            },
        },
        unloading: {
            ship_to: {
                duns: shipment.unloading.shipTo.duns,
                plant_code: shipment.unloading.shipTo.plantCode,
                supplier_code: shipment.unloading.shipTo.supplierCode,
            },
            unloading_access_code: shipment.unloading.unloadingAccessCode,
        },
        delivery_notes: shipment.deliveryNotes.map((deliveryNote) => ({
            delivery_note_number: deliveryNote.deliveryNoteNumber,
        })),
    })),
})

const toTransportationReport = (src: TransportationReportResponseRest): TransportationReportResult => ({
    reportId: src.report_id,
})

export const transportationReportApi = createApi({
    reducerPath: "transportationReportApi",
    tagTypes: [TRANSPORTATION_REPORT_API_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: transportationReportBaseUrl,
        prepareHeaders: async (headers) => {
            headers.set("authorization", `Bearer ${await getTokenFromSessionStorage()}`)
            headers.set("content-type", "application/json")
            headers.set("accept", "application/json")
            return headers
        },
    }),
    endpoints: (builder) => ({
        postTransportationReport: builder.mutation<TransportationReportResult, TransportationReport>({
            query: (report) => ({
                url: "/transportation-reports",
                method: "POST",
                body: toTransportationReportRest(report),
            }),
            transformResponse: (response: unknown) => {
                const transportationReportRest = transportationReportResponseRestSchema.parse(response)
                return toTransportationReport(transportationReportRest)
            },
            invalidatesTags: [{ type: TRANSPORTATION_REPORT_API_TAG }],
        }),
    }),
})

export const {
    usePostTransportationReportMutation,
} = transportationReportApi
