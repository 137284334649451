import * as React from "react"
import { ReactNode, useMemo, useState } from "react"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import {
    getNonCancelledShipments,
    Shipment,
    ShipmentPriority,
    TimeWindow,
    TransportOrder,
    VehicleType,
} from "../../../model/transportorder/transportOrder"
import { FormattedMessage } from "react-intl"
import {
    GlobalValidationContextState,
    ValidationContext,
    ValidationContextProvider
} from "../../Form/validation/VaildationContext"
import { AssetSelect } from "../../Asset/AssetSelect"
import {
    applyConfirmationToAllShipments,
    areConfirmationsEqual,
    Confirmation,
    ShipmentConfirmation
} from "../../../model/confirmation/Confirmation"
import { TimeWindowBookingIdInput } from "./TimeWindowBookingIdInput"
import {
    doesTransportOrderSupportDriverPhoneNumber,
    doesTransportOrderSupportLoadingTimeWindow,
    doesTransportOrderSupportTwoDrivers,
    doesTransportOrderSupportUnloadingTimeWindow,
    doesTransportOrderSupportVehicleType,
} from "../../../model/transportorder/transportOrderEditing"
import { DriverPhoneNumberInput } from "./DriverPhoneNumberInput"
import { VehicleTypeInput } from "./VehicleTypeInput"
import { DetailViewJumpPoint } from "./DetailViewJumpPoint"
import { TimeWindowInput } from "../timewindow/TimeWindowInput"
import { ValidFromAndTimeWindowCompleteValidator, ValidToAndTimeWindowCompleteValidator } from "./validator"
import { containsUrgentShipment } from "../../../helper/transportOrderHelper"
import { BorderoNumberInput } from "./BorderoNumberInput"
import { CrossDockIdSelect } from "../../Form/CrossDockIdSelect"
import { useCrossDocksOfAccount } from "../../../hooks/crossDocks"
import { ConfirmationActionButton } from "./ConfirmationActionButton"
import { timezoneOfUnloadingAddress } from "../../../timezones/timezones"
import { MaterialOrderConfirmationLoadingTimeWindowInput } from "./MaterialOrderConfirmationLoadingTimeWindowInput"
import { TwoDriversCheckbox } from "./TwoDriversCheckBox"

interface Props {
    show: boolean
    transportOrder: TransportOrder
    onCancel: () => void
    onConfirm: (shipmentConfirmations: ShipmentConfirmation[]) => Promise<void>
    toDetailsButtonCallback: () => void
}

interface InitialState {
    confirmation: Confirmation
    sameAccessCodesAtUnloading: boolean
    numberOfShipments: number
    appliesToAllShipments: boolean
}

const InfoBox = (props: { messageId: string }): React.ReactElement => {

    const Strong = (chucks: ReactNode): React.ReactElement => (<strong>{chucks}</strong>)

    return <div className="alert alert-info">
        <div className={"display-flex align-items-center"}>
            <span className={"rioglyph rioglyph-info-sign text-size-20 margin-right-15"}/>
            <FormattedMessage
                id={props.messageId}
                values={{ strong: (chunks: ReactNode): React.ReactElement => Strong(chunks) }} tagName={"div"}/>
        </div>
    </div>
}

const areAllConfirmationDraftsEqual = (shipmentsToConfirm: Shipment[]): boolean => {
    const allConfirmationDrafts = shipmentsToConfirm.map(it => it.confirmationDraft)
    return allConfirmationDrafts.every(
        (confirmation) => areConfirmationsEqual(confirmation, allConfirmationDrafts[0])
    )
}

const getInitialState = (transportOrder: TransportOrder): InitialState => {
    const shipmentsToConfirm = getNonCancelledShipments(transportOrder)
    const firstConfirmedShipment = shipmentsToConfirm.find((it) => it.isConfirmed)
    const appliesToAllShipments = areAllConfirmationDraftsEqual(shipmentsToConfirm)
    const accessCodesAtUnloading = shipmentsToConfirm
        .map(it => it.confirmationDraft.accessCodeAtUnloading)
        .filter((accessCode): accessCode is string => accessCode !== undefined && accessCode !== null)
    const sameAccessCodesAtUnloading = accessCodesAtUnloading.every(it => it === accessCodesAtUnloading[0])

    if (firstConfirmedShipment !== undefined) {
        return {
            confirmation: firstConfirmedShipment.confirmationDraft,
            numberOfShipments: shipmentsToConfirm.length,
            appliesToAllShipments,
            sameAccessCodesAtUnloading,
        }
    }

    return {
        confirmation: shipmentsToConfirm.length > 0 ? shipmentsToConfirm[0].confirmationDraft : {},
        numberOfShipments: shipmentsToConfirm.length,
        appliesToAllShipments,
        sameAccessCodesAtUnloading,
    }
}

export const MaterialOrderConfirmationDialog = (props: Props): React.ReactElement => {

    const ctx = React.useContext(ValidationContext)
    const transportOrder = props.transportOrder

    const initialState = useMemo(() => getInitialState(transportOrder), [transportOrder])
    const [confirmation, setConfirmation] = useState<Confirmation>(initialState.confirmation)
    const [appliesToAllShipments, setAppliesToAllShipments] = useState<boolean>(initialState.appliesToAllShipments)

    const title = <FormattedMessage id={"transportOrder.confirmation.dialog.title"}/>

    const numberOfShipments = transportOrder._embedded?.shipments.length ?? 0

    const appliesToAllShipmentsCheckbox = (
        <div className="display-flex justify-content-start">
            <Checkbox
                className="padding-top-10 padding-bottom-10"
                onClick={(): void => setAppliesToAllShipments(!appliesToAllShipments)}
                checked={appliesToAllShipments}
            >
                <FormattedMessage id={"transportOrder.confirmation.appliesToAllShipments.sofa.label"}/>
            </Checkbox>
        </div>
    )

    const firstShipment = transportOrder._embedded?.shipments[0]
    const priorityOfFirst = firstShipment?.priority
    const { crossDocks, loading } = useCrossDocksOfAccount()

    const allShipmentsConfiguration = (<>
        <AssetSelect
            label={<FormattedMessage id="transportOrder.confirmation.input.label.asset"/>}
            assetId={confirmation.asset?.id}
            onAssetSelected={(asset): void => {
                const baseConfirmation: Confirmation = confirmation ? {
                    ...confirmation,
                } : {}
                setConfirmation({
                    ...baseConfirmation, asset: asset ? {
                        id: asset.id,
                        licensePlate: asset.licensePlate,
                        countryCode: asset.licensePlateCountryCode
                    } : undefined
                })
            }}
            className={"margin-bottom-20"}
        />
        <VehicleTypeInput
            show={doesTransportOrderSupportVehicleType(transportOrder)}
            value={confirmation.vehicleType}
            onChange={(value: VehicleType | undefined): void => setConfirmation({
                ...confirmation,
                vehicleType: value,
            })}
            className={"margin-bottom-20"}
        />
        <DriverPhoneNumberInput
            show={doesTransportOrderSupportDriverPhoneNumber(transportOrder)}
            value={confirmation.phoneNumber}
            onChange={(value: string): void => setConfirmation({
                ...confirmation,
                phoneNumber: value || undefined,
            })}
            className={"margin-bottom-20"}
        />
        <MaterialOrderConfirmationLoadingTimeWindowInput
            value={confirmation.loadingTimeWindow}
            show={doesTransportOrderSupportLoadingTimeWindow(transportOrder)}
            shipment={firstShipment}
            onChange={(value: TimeWindow | undefined): void => {
                const baseConfirmation: Confirmation = confirmation || {}
                setConfirmation({
                    ...baseConfirmation,
                    loadingTimeWindow: value,
                })
            }}
            dateTimePropsTo={{
                className: "margin-bottom-20",
                datePickerProps: {
                    className: "margin-bottom-0",
                }
            }}
            dateTimePropsFrom={{
                className: "margin-bottom-20",
                datePickerProps: {
                    className: "margin-bottom-0",
                }
            }}
        />
        <TwoDriversCheckbox
            show={doesTransportOrderSupportTwoDrivers(transportOrder)}
            confirmation={confirmation}
            onChange={(value): void => {
                setConfirmation({
                    ...confirmation,
                    twoDrivers: value,
                })}
            }
            className={"margin-bottom-20"}
        />
        <TimeWindowBookingIdInput
            confirmation={confirmation}
            property={"accessCodeAtUnloading"}
            onChange={(value: Confirmation): void => setConfirmation({
                ...value,
                accessCodeAtUnloading: value.accessCodeAtUnloading || undefined
            })}
            className={"margin-bottom-20"}
        />
        <TimeWindowInput
            value={confirmation.unloadingTimeWindow}
            show={doesTransportOrderSupportUnloadingTimeWindow(transportOrder)}
            timezone={timezoneOfUnloadingAddress(transportOrder)}
            labelFrom={<FormattedMessage id="transportOrder.confirmation.input.label.unloadingTimeWindow.from"/>}
            labelTo={<FormattedMessage id="transportOrder.confirmation.input.label.unloadingTimeWindow.to"/>}
            validateFrom={ValidFromAndTimeWindowCompleteValidator}
            validateTo={ValidToAndTimeWindowCompleteValidator}
            onChange={(value: TimeWindow | undefined): void => {
                setConfirmation({
                    ...confirmation,
                    unloadingTimeWindow: value,
                })
            }}
            dateTimePropsTo={{
                className: "margin-bottom-20",
                datePickerProps: {
                    className: "margin-bottom-0",
                }
            }}
            dateTimePropsFrom={{
                className: "margin-bottom-20",
                datePickerProps: {
                    className: "margin-bottom-0",
                }
            }}
        />
        <BorderoNumberInput
            show={true}
            confirmation={confirmation}
            onChange={(value: Confirmation): void => {
                setConfirmation({
                    ...confirmation,
                    loadId: value.loadId || undefined,
                })
            }}
            className={"margin-bottom-20"}
        />
        <CrossDockIdSelect
            hide={priorityOfFirst !== ShipmentPriority.REGULAR || crossDocks.length === 0}
            options={crossDocks ?? []}
            disabled={loading}
            value={confirmation.crossDockId}
            onChange={(newCrossDockId: string | undefined): void => {
                setConfirmation({
                    ...confirmation,
                    crossDockId: newCrossDockId,
                })
            }}
            className={"margin-bottom-20"}
        />
        <div className="margin-top-20">
            <InfoBox messageId={"transportOrder.confirmation.dialog.body.note"}/>
        </div>
    </>)

    const displayLabelBasedOnShipmentPriority = (): string | undefined =>
        containsUrgentShipment(props.transportOrder._embedded?.shipments) ?
            "transportOrder.type.material.term.urgent" : "transportOrder.type.material.term"

    const body = <ValidationContextProvider value={GlobalValidationContextState}>
        <FormattedMessage id={"transportOrder.confirmation.dialog.body.text"}
            values={{
                type: <strong><FormattedMessage id={displayLabelBasedOnShipmentPriority()} /></strong>
            }}/>
        {numberOfShipments > 1 && appliesToAllShipmentsCheckbox}
        {appliesToAllShipments
            ? allShipmentsConfiguration
            : <DetailViewJumpPoint
                transportOrderId={transportOrder.id}
                additionalInfoBoxMessageId={initialState.sameAccessCodesAtUnloading ? undefined : "transportOrder.confirmation.dialog.multipleAccessCode.text"}
            />}
    </ValidationContextProvider>

    const footer = <ConfirmationActionButton
        onClick={(): Promise<void> => {
            if (!ctx.hasAnyError()) {
                return props.onConfirm(applyConfirmationToAllShipments(confirmation, transportOrder))
            } else {
                ctx.showAllValidationMessages()
                return Promise.reject()
            }
        }}
        disabled={!appliesToAllShipments}
        transportOrder={transportOrder}
        confirmations={[confirmation]}
    />

    return <Dialog
        show={props.show}
        disableEsc={false}
        body={body}
        title={title}
        showCloseButton={true}
        footer={footer}
        bsSize={Dialog.SIZE_SM}
        onClose={props.onCancel}
    />
}
