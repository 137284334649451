export interface TransportationReportResult {
    reportId: string
}

export interface TransportationReport {
    transportDetails: TransportDetails
    shipments: Shipment[]
}

export interface TransportDetails {
    recipientIdentifier: string
    freightForwarder: FreightForwarder
    transportMode: TransportMode
    borderoNumber: string
    borderoDate: string
    timeWindow: TimeWindow
}

export interface FreightForwarder {
    duns: string
    supplierCode: string
}

export const meansOfTransport = [
    "EUROTRAILER",
    "MEGATRAILER",
    "JUMBO_SWAP_BODY",
    "JUMBO_TRAILER_PULL",
    "TRUCK_7_5_TON",
    "VAN_OR_BUS",
    "CAR",
    "GIGALINER",
    "TAUTLINER",
    "GOOSENECK_CHASSIS",
] as const

export interface TransportMode {
    type: "ROAD"
    meansOfTransport: typeof meansOfTransport[number]
    licensePlate: string
    licensePlateCountryCode: string
}

export interface TimeWindow {
    from: string
    to: string
}

export interface Shipment {
    shipmentNumber: string
    loading: Loading
    unloading: Unloading
    deliveryNotes: DeliveryNote[]
}

export interface Loading {
    shipFrom: ShipFrom
}

export interface Unloading {
    shipTo: ShipTo
    unloadingAccessCode: string
}

export interface ShipFrom {
    duns: string
    supplierCode: string
}

export interface ShipTo {
    duns: string
    plantCode: string
    supplierCode: string
}

export interface DeliveryNote {
    deliveryNoteNumber: string
}
