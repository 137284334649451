import { useState } from "react"
import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import CustomState from "@rio-cloud/rio-uikit/CustomState"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import Button, { STYLES_MAP } from "@rio-cloud/rio-uikit/Button"
import { FormProvider, useForm } from "react-hook-form"
import { TransportationReport } from "../../model/transportation-report/transportation-report"
import { usePostTransportationReportMutation } from "../../client/transportation-report/transportationReportApi"
import Notification from "@rio-cloud/rio-uikit/Notification"
import { CopyToClipboard } from "./CopyToClipboard"
import { FreightPaymentDetailsInput } from "./FreightPaymentDetailsInput"
import { SubmitConfirmationDialog } from "./SubmitConfirmationDialog"

interface FreightPaymentDetailsDialogProps {
    showDialog: boolean
    onClose: () => void
}

type FreightPaymentDetailsFormInput = TransportationReport

export const FreightPaymentDetailsDialog = (props: FreightPaymentDetailsDialogProps): ReactElement => {
    const { onClose, showDialog } = props
    const defaultValues = {
        transportDetails: {
            transportMode: {
                type: "ROAD",
            }
        },
        shipments: [
            {
                deliveryNotes: [{}]
            }
        ]
    } as FreightPaymentDetailsFormInput

    const [postTransportationReportMutation, postTransportationReportMutationState] = usePostTransportationReportMutation()
    const [showSubmitConfirmationDialog, setShowSubmitConfirmationDialog] = useState(false)

    const formMethods = useForm<FreightPaymentDetailsFormInput>({
        mode: "all",
        defaultValues: defaultValues,
    })

    const handleSubmitConfirmation = () => {
        formMethods.trigger().then(isValid => {
            if (isValid && !isLoading) {
                postTransportationReportMutation(formMethods.getValues())
                    .unwrap()
                    .then(() => {
                        setShowSubmitConfirmationDialog(false)
                    })
                    .catch(() => {
                        Notification.error(
                            <FormattedMessage
                                id={"freightPaymentDetails.dialog.error.message"}
                            />
                        )
                        setShowSubmitConfirmationDialog(false)
                    })
            }
        })
    }

    const { isLoading, isSuccess, isError, data, reset: reportIdReset } = postTransportationReportMutationState
    const showDialogFooter = !isSuccess || isError
    const showSuccessMessage = isSuccess && !isError

    const submitFreightPaymentDetails = () => {
        formMethods.trigger().then(isValid => {
            isValid &&
            setShowSubmitConfirmationDialog(true)
        })
    }

    const cancel = () => {
        formMethods.reset(defaultValues)
        reportIdReset()
        onClose()
    }

    return (
        <Dialog
            bsSize={showSuccessMessage ? Dialog.SIZE_SM : Dialog.SIZE_LG}
            onClose={cancel}
            show={showDialog}
            useOverflow={!showSuccessMessage}
        >
            <FormProvider {...formMethods}>
                <Dialog.Title
                    title={<FormattedMessage id={"freightPaymentDetails.dialog.title"}/>}
                    subtitle={<FormattedMessage id={"freightPaymentDetails.dialog.subtitle"}/>}
                />
                <Dialog.Body>
                    {showSuccessMessage ?
                        <CustomState
                            message={
                                <div className={"display-flex flex-wrap text-color-warning"}>
                                    <div className="display-flex align-items-center margin-bottom-15">
                                        <span className="rioglyph rioglyph-warning-sign text-size-h1 margin-right-10"/>
                                        <FormattedMessage id={"freightPaymentDetails.dialog.success.message"}/>
                                    </div>
                                    <div className="flex-1-1">
                                        <CopyToClipboard text={data.reportId}/>
                                    </div>
                                </div>
                            }
                            icons={[
                                {
                                    name: "rioglyph-ok-sign",
                                    color: "text-color-success",
                                    className: "text-size-300pct",
                                },
                            ]}
                        /> : <FreightPaymentDetailsInput/>}
                </Dialog.Body>
                {showDialogFooter ?
                    <Dialog.Footer>
                        <div className={"pull-right btn-toolbar"}>
                            <Button onClick={cancel}>
                                <FormattedMessage id={"notification.settings.email.delete.cancel"}/>
                            </Button>
                            <Button bsStyle={STYLES_MAP.PRIMARY} onClick={submitFreightPaymentDetails}>
                                <FormattedMessage id={"freightPaymentDetails.button.label"}/>
                            </Button>
                            <SubmitConfirmationDialog
                                show={showSubmitConfirmationDialog}
                                disableConfirm={isLoading}
                                onClickConfirm={handleSubmitConfirmation}
                                onClickCancel={() => setShowSubmitConfirmationDialog(false)}/>
                        </div>
                    </Dialog.Footer> : null}
            </FormProvider>
        </Dialog>
    )
}