import useClipboard from "@rio-cloud/rio-uikit/useClipboard"
import Button from "@rio-cloud/rio-uikit/Button"

export const CopyToClipboard = (props: { text: string }) => {
    const clipboard = useClipboard()

    return (
        <div className={"panel panel-default panel-body"}>
            <Button
                bsStyle={"muted"}
                bsSize={"md"}
                iconName={"rioglyph-duplicate"}
                onClick={() => clipboard.copy(props.text)}
                multiline>
                {props.text}
            </Button>
        </div>
    )
}