import { useFieldArray, useFormState } from "react-hook-form"
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel"
import { FormattedMessage } from "react-intl"
import { FormTextInput } from "../Form/FormTextInput"
import { DeliveryNote, Shipment, TransportationReport } from "../../model/transportation-report/transportation-report"
import Button from "@rio-cloud/rio-uikit/Button"

type ShipmentsFormInput = Pick<TransportationReport, "shipments">

const chunked = <T, >(array: Array<T>, chunkSize: number): Array<Array<T>> => {
    const chunks: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize))
    }
    return chunks
}

const ShipmentPanel = (props: { shipmentIndex: number; removeShipment: (() => void) | undefined }) => {
    const { removeShipment, shipmentIndex } = props
    const { fields, append, remove } =
        useFieldArray<ShipmentsFormInput>({ name: `shipments.${shipmentIndex}.deliveryNotes` })
    const deliverNoteLines = chunked(fields, 3)

    const formState = useFormState({ name: `shipments.${shipmentIndex}` })
    const hasErrors = formState.errors.shipments !== undefined && formState.errors.shipments[shipmentIndex] !== undefined

    return <ExpanderPanel
        open
        title={<>
            {hasErrors ? <span className={"rioglyph rioglyph-error-sign padding-right-5"}/> : undefined}
            <FormattedMessage id={"shipment.numbered"} values={{ num: shipmentIndex + 1 }}/>
            {removeShipment !== undefined && <span className={"padding-left-10"}>
                <span
                    className={"rioglyph rioglyph-trash text-color-gray cursor-pointer"}
                    data-testid={`remove-shipment-${shipmentIndex}`}
                    onClick={event => {
                        event.stopPropagation() // Stop expander from collapsing
                        removeShipment()
                    }}/>
            </span>}
        </>}
        iconLeft={true}
        bsStyle={"separator"}
        titleClassName={hasErrors ? "text-danger" : ""}
        unmountOnExit={false}
    >
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.shipmentNumber`}
                    label={<FormattedMessage id={"shipment.shipmentNumber"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipmentNumber.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.loading.shipFrom.duns`}
                    label={<FormattedMessage id={"shipFrom.duns"}/>}
                    required={true}
                    mask={"999999999"}
                    minLength={9}
                    maxLength={9}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipFrom.duns.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.loading.shipFrom.supplierCode`}
                    label={<FormattedMessage id={"shipFrom.supplierCode"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipFrom.supplierCode.required"}/> }}
                />
            </div>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.unloading.shipTo.plantCode`}
                    label={<FormattedMessage id={"shipTo.plantCode"}/>}
                    required={false}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.unloading.shipTo.duns`}
                    label={<FormattedMessage id={"shipTo.duns"}/>}
                    required={true}
                    mask={"999999999"}
                    minLength={9}
                    maxLength={9}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipTo.duns.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.unloading.shipTo.supplierCode`}
                    label={<FormattedMessage id={"shipTo.supplierCode"}/>}
                    required={false}
                />
            </div>
        </div>
        <div className={"display-flex gap-20 margin-bottom-15"}>
            <div className={"flex-basis-100pct"}>
                <FormTextInput<ShipmentsFormInput>
                    fieldName={`shipments.${shipmentIndex}.unloading.unloadingAccessCode`}
                    label={<FormattedMessage id={"transportOrder.shipment.address.accessCode"}/>}
                    required={true}
                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.shipTo.accessCode.required"}/> }}
                />
            </div>
            <div className={"flex-basis-100pct"}/>
            <div className={"flex-basis-100pct"}/>
        </div>
        {
            deliverNoteLines.map((chunk, chunkIndex) =>
                <div key={chunkIndex}
                    className={"display-flex gap-20 margin-bottom-15 justify-content-start align-items-end"}>
                    {
                        chunk.map((_, index) => {
                            const deliveryNoteIndex = chunkIndex * 3 + index
                            return <div
                                key={`shipment-${shipmentIndex}-delivery-note-${deliveryNoteIndex}-of-${fields.length}`}
                                className={"flex-basis-30pct"}>
                                <div className={"align-items-center"}>
                                    <span className={"rioglyph rioglyph-parcel padding-right-5"}/>
                                    <span className={"padding-right-15"}>
                                        <FormattedMessage id={"shipment.freight.numbered"}
                                            values={{ num: deliveryNoteIndex + 1 }}/>
                                    </span>
                                    {fields.length > 1 &&
                                        <span
                                            className={"rioglyph rioglyph-trash text-color-gray cursor-pointer"}
                                            data-testid={`remove-delivery-note-${shipmentIndex}-${deliveryNoteIndex}`}
                                            onClick={() => remove(deliveryNoteIndex)}
                                        />}
                                </div>
                                <FormTextInput<ShipmentsFormInput>
                                    fieldName={`shipments.${shipmentIndex}.deliveryNotes.${deliveryNoteIndex}.deliveryNoteNumber`}
                                    label={<FormattedMessage id={"shipment.deliveryNoteNumber"}/>}
                                    required={true}
                                    validationMessageOverrides={{ required: <FormattedMessage id={"freightPaymentDetails.deliveryNoteNumber.required"}/> }}
                                />
                            </div>
                        })
                    }
                    {chunkIndex === deliverNoteLines.length - 1 &&
                        <span
                            className={"rioglyph rioglyph-plus-sign text-color-gray cursor-pointer text-size-h3 margin-bottom-20"}
                            data-testid={`add-delivery-note-${shipmentIndex}`}
                            onClick={() => append({} as DeliveryNote)}
                        />}
                </div>)

        }
    </ExpanderPanel>
}

export const ShipmentPanelList = () => {

    const { fields, append, remove } = useFieldArray<ShipmentsFormInput>({
        name: "shipments",
        rules: {
            required: true,
            minLength: 1,
        },
    })

    return <>
        {fields.map((_, index) =>
            <ShipmentPanel
                key={`shipment-${index}-of-${fields.length}`}
                shipmentIndex={index}
                removeShipment={fields.length > 1 ? () => remove(index) : undefined}
            />)}

        <div className={"text-center"}>
            <Button onClick={() => append({ deliveryNotes: [{}] } as Shipment)}>
                <span className={"rioglyph rioglyph-plus-light"}/>
                <FormattedMessage id={"shipment.add"}/>
            </Button>
        </div>
    </>
}